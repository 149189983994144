import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { FaBook, FaHeadphones, FaVideo } from "react-icons/fa";
import * as footerStyles from "./footer.module.css";
import ContactForm from "./contact-form";
import { Trans } from "@lingui/macro";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";

const Footer = ({ section4, formHeading }) => {
  const { locale } = useLocalization();
  const i18nReadLinks = {
    en: "https://www.biblegateway.com/passage/?search=Luke%201&version=ESV",
    fa: "https://www.bible.com/bible/118/LUK.INTRO1.nmv",
    tk: "http://ibt.org.ru/ftpmirror/pub/ebook/TKM/TKL/Luke_Part.pdf",
    tr: "https://halkdilinde.com/pdf/03_Luka.pdf",
    uz: "http://ibt.org.ru/ftpmirror/pub/ebook/UZB/UZV/Luke_Part.pdf",
  };
  return (
    <div className="row row-only-text">
      <div className="row-container" style={{ marginBottom: "20px" }}>
        <div className="columns">
          <div className="column">
            <MDXRenderer>{section4.body}</MDXRenderer>
          </div>
        </div>
        <div
          id="footer-btns"
          className={`columns three-columns ${footerStyles.footerColumns}`}
        >
          <div className={footerStyles.footerColumn}>
            <a
              href={i18nReadLinks[locale]}
              rel="noreferrer"
              target="_blank"
              className="row"
            >
              <div className="row-bg-image-container">
                <div
                  className={`row-bg-image-overlay ${footerStyles.footerColumnBgOverlay}`}
                ></div>
                <StaticImage
                  className="row-bg-image"
                  layout="fullWidth"
                  alt="Trabzon"
                  src={
                    "https://images.unsplash.com/photo-1480185660311-81671f39489c?w=600"
                  }
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <div className="row-container">
                <h2 className={footerStyles.columnText}>
                  <FaBook />
                  <span>
                    <Trans id="footer.column1">Read</Trans>
                  </span>
                </h2>
              </div>
            </a>
          </div>
          <div className={footerStyles.footerColumn}>
            <Link to="/audio/" className="row">
              <div className="row-bg-image-container">
                <div
                  className={`row-bg-image-overlay ${footerStyles.footerColumnBgOverlay}`}
                ></div>
                <StaticImage
                  className="row-bg-image"
                  layout="fullWidth"
                  alt="Trabzon"
                  src={
                    "https://images.unsplash.com/photo-1528457213615-b42528b7d61e?w=600"
                  }
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <div className="row-container">
                <h2 className={footerStyles.columnText}>
                  <FaHeadphones />
                  <span>
                    <Trans id="footer.column2">Listen</Trans>
                  </span>
                </h2>
              </div>
            </Link>
          </div>
          <div className={footerStyles.footerColumn}>
            <Link to="/video/" className="row">
              <div className="row-bg-image-container">
                <div
                  className={`row-bg-image-overlay ${footerStyles.footerColumnBgOverlay}`}
                ></div>
                <StaticImage
                  className="row-bg-image"
                  layout="fullWidth"
                  alt="Trabzon"
                  src={
                    "https://images.unsplash.com/photo-1565870100382-f0a510db3cd1?w=600"
                  }
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <div className="row-container">
                <h2 className={footerStyles.columnText}>
                  <FaVideo />
                  <span>
                    <Trans id="footer.column3">Watch</Trans>
                  </span>
                </h2>
              </div>
            </Link>
          </div>
        </div>

        <div className={footerStyles.contactFormWrapper}>
          <MDXRenderer>{formHeading.body}</MDXRenderer>
          <ContactForm />
        </div>
        <p className={footerStyles.lastStatement}>
          <Trans id="footer.statement">
            We are from a group of Christian churches with more than 69 million
            members in 190 countries.
          </Trans>
        </p>
      </div>
    </div>
  );
};
export default Footer;

import * as Yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

const COUNTRY_LIST = [
  "TR",
  "AZ",
  "UZ",
  "TM",
  "IR",
  "AF",
  "PK",
  "TJ",
  "KG",
  "KZ",
  "IQ",
  "SY",
  "GE",
  "AM",
  "RU",
];

const isValidPhoneNumberList = (value, countries) => {
  const valid = countries.find((code) => isValidPhoneNumber(value, code));
  return valid ? true : false;
};

function yupPhone(errorMessage) {
  const errMsg = errorMessage
    ? errorMessage
    : `\${path} must be a valid phone number`;
  return this.test("phone", errMsg, (value) => {
    try {
      if (value === undefined || value === "") {
        return true;
      }
      const phone = isValidPhoneNumberList(value, COUNTRY_LIST);
      return phone ? true : false;
    } catch {
      return false;
    }
  });
}

Yup.addMethod(Yup.string, "phone", yupPhone);

const i18nMessages = {
  en: {
    required: "This field is required.",
    usernameMin: "Usernames must be longer than 2 characters.",
    usernameMax: "Usernames must be shorter than 100 characters.",
    phoneOnlyNum: "Only numbers are allowed.",
    phoneInvalid: "Invalid phone number.",
    emailInvalid: "Invalid email address.",
    gdpr: "Must accept the agreement.",
  },
  fa: {
    required: "این قسمت الزامی است",
    usernameMin: "نام کاربری باید بیش از دو حرف باشد",
    usernameMax: "نام کاربری باید کوتاه تر از صد حرف باشد",
    phoneOnlyNum: "فقط اعداد مجاز است",
    phoneInvalid: "تلفن نامعتبر است",
    emailInvalid: "ایمیل نامعتبر است",
    gdpr: "این قسمت الزامی است",
  },
  tk: {
    required: "Bu meýdan hökmanydyr.",
    usernameMin: "Ulanyjy atlary 2 harpdan uzyn bolmaly.",
    usernameMax: "Ulanyjy atlary 100 harpdan gysga bolmaly.",
    phoneOnlyNum: "Diňe sanlara rugsat berilýär.",
    phoneInvalid: "Telefon nädogry.",
    emailInvalid: "E-poçta nädogry.",
    gdpr: "Bu meýdan hökmanydyr.",
  },
  tr: {
    required: "Bu alan gereklidir.",
    usernameMin: "Kullanıcı adları 2 harften uzun olmalıdır.",
    usernameMax: "Kullanıcı adları 100 harften kısa olmalıdır.",
    phoneOnlyNum: "Yalnızca sayılara izin verilir.",
    phoneInvalid: "Telefon geçersiz.",
    emailInvalid: "E-posta geçersiz.",
    gdpr: "Bu alan gereklidir.",
  },
  uz: {
    required: "Ушбу қатор тўлдирилиши шарт.",
    usernameMin: "Фойдаланувчи номлари 2 ҳарфдан узун бўлиши керак.",
    usernameMax: "Фойдаланувчи номлари 100 ҳарфдан қисқа бўлиши керак.",
    phoneOnlyNum: "Фақат рақамларга рухсат берилган.",
    phoneInvalid: "Телефон яроқсиз.",
    emailInvalid: "Електрон почта яроқсиз.",
    gdpr: "Ушбу қатор тўлдирилиши шарт.",
  },
};

const ContactValidationSchema = (locale) => {
  const t = i18nMessages[locale];
  return Yup.object().shape({
    contact: Yup.string().required(t.required),
    username: Yup.string().when("contact", {
      is: (contact) => contact === "facebook" || contact === "telegram",
      then: Yup.string()
        .min(2, t.usernameMin)
        .max(100, t.usernameMax)
        .required(t.required),
      otherwise: Yup.string(),
    }),
    phone: Yup.string().when("contact", {
      is: (contact) => contact === "whatsapp" || contact === "signal",
      then: Yup.string().phone(t.phoneInvalid).required(t.required),
      otherwise: Yup.string(),
    }),
    email: Yup.string().when("contact", {
      is: (contact) => contact === "email",
      then: Yup.string().email(t.emailInvalid).required(t.required),
      otherwise: Yup.string(),
    }),
    gdpr: Yup.boolean().oneOf([true], t.gdpr),
  });
};

export default ContactValidationSchema;

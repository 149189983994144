import * as React from "react";
import { Form, Formik } from "formik";
import * as contactFormStyles from "./contact-form.module.css";
import FieldWrapper from "./field-wrapper";
import ContactValidationSchema from "./contact-form-validation-schema";
import { useLocalization } from "gatsby-theme-i18n";
import { t } from "@lingui/macro";

const ContactForm = () => {
  const { locale } = useLocalization();
  const localValidation = ContactValidationSchema(locale);
  const [isSent, setIsSent] = React.useState(false);
  const [isUnknownerror, setIsUnknownError] = React.useState(false);

  return (
    <Formik
      initialValues={{
        contact: "whatsapp",
        username: "",
        phone: "",
        email: "",
        gdpr: false,
      }}
      validationSchema={localValidation}
      onSubmit={(values, { setSubmitting }) => {
        const newValues = {
          lang: locale,
          gdpr: values.gdpr,
          contact: values.contact,
        };
        if (values.contact === "facebook" || values.contact === "telegram") {
          newValues["username"] = values.username;
        }
        if (values.contact === "whatsapp" || values.contact === "signal") {
          newValues["phone"] = values.phone;
        }
        if (values.contact === "email") {
          newValues["email"] = values.email;
        }

        setIsUnknownError(false);
        const url = "https://forms.mujde.workers.dev/forms/contact";
        fetch(url, {
          method: "POST",
          body: JSON.stringify(newValues),
        })
          .then((response) => {
            if (response.status !== 200) {
              throw new Error("Server Error");
            } else {
              return response.json();
            }
          })
          .then(() => {
            setSubmitting(false);
            setIsSent(true);
          })
          .catch(() => {
            setSubmitting(false);
            setIsUnknownError(true);
          });
      }}
    >
      {({ values, isSubmitting }) => (
        <div>
          {isSent ? (
            <div className={contactFormStyles.thankyouPage}>
              <h1>{t({ id: "form.thankyou", message: "Thank you!" })}</h1>
            </div>
          ) : (
            <Form className={contactFormStyles.contactForm}>
              <p
                style={{
                  display: isUnknownerror ? "block" : "none",
                  color: "white",
                  marginBottom: "20px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {t({
                  id: "form.unkownError",
                  message: "There was an unknown error. Please try again.",
                })}
              </p>
              <div className={contactFormStyles.inputTwoCol}>
                <FieldWrapper
                  fieldName="contact"
                  label="Contact Type*"
                  hideLabel={true}
                  as="select"
                >
                  <option value="whatsapp">
                    {t({ id: "form.contact.option1", message: "WhatsApp" })}
                  </option>
                  <option value="facebook">
                    {t({ id: "form.contact.option2", message: "Facebook" })}
                  </option>
                  <option value="telegram">
                    {t({ id: "form.contact.option3", message: "Telegram" })}
                  </option>
                  <option value="signal">
                    {t({ id: "form.contact.option4", message: "Signal" })}
                  </option>
                  <option value="email">
                    {t({ id: "form.contact.option5", message: "Email" })}
                  </option>
                </FieldWrapper>
                {values.contact === "facebook" ||
                values.contact === "telegram" ? (
                  <FieldWrapper
                    fieldName="username"
                    label={t({ id: "form.username", message: "Username" })}
                    placeholder={t({
                      id: "form.username",
                      message: "Username",
                    })}
                    hideLabel={true}
                  />
                ) : null}
                {values.contact === "whatsapp" ||
                values.contact === "signal" ? (
                  <FieldWrapper
                    fieldName="phone"
                    label={t({ id: "form.phone", message: "Phone" })}
                    placeholder={t({ id: "form.phone", message: "Phone" })}
                    hideLabel={true}
                  />
                ) : null}
                {values.contact === "email" ? (
                  <FieldWrapper
                    fieldName="email"
                    label={t({ id: "form.email", message: "Email" })}
                    placeholder={t({ id: "form.email", message: "Email" })}
                    hideLabel={true}
                  />
                ) : null}
              </div>

              <FieldWrapper
                fieldName="gdpr"
                label={t({
                  id: "form.gdpr",
                  message:
                    "I consent to having this website store my submitted information so they can respond to my inquiry",
                })}
                type="checkbox"
              />

              <>
                {isSubmitting ? (
                  <button disabled type="submit">
                    <span className={contactFormStyles.dotFlashing}></span>
                  </button>
                ) : (
                  <button disabled={isSubmitting} type="submit">
                    <span>{t({ id: "form.send", message: "Send" })}</span>
                  </button>
                )}
              </>
            </Form>
          )}
        </div>
      )}
    </Formik>
  );
};

export default ContactForm;

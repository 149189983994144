import * as React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { useLocalization } from "gatsby-theme-i18n";
import Seo from "../components/seo";
import Header from "../components/header";
import Hero from "../components/hero";
import Footer from "../components/footer";

const IndexPage = ({ data }) => {
  const { section1, section2, section3, section4, formHeading } = data;
  const { locale, config } = useLocalization();
  const { langDir, localName } = config.find((item) => item.code === locale);
  return (
    <main>
      <Seo title={localName}>
        <html lang={locale} dir={langDir} />
      </Seo>
      <Header />
      <Hero />
      <div className="row row-only-text">
        <div className="row-container">
          <div className="columns">
            <div className="column">
              <MDXRenderer>{section1.body}</MDXRenderer>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-only-image">
        <div className="row-bg-image-container">
          <StaticImage
            className="row-bg-image"
            layout="fullWidth"
            alt="Trabzon"
            src={
              "https://images.unsplash.com/photo-1587045074958-07c6a23b8822?w=2000"
            }
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
      <div className="row row-only-text">
        <div className="row-container">
          <div className="columns">
            <div className="column">
              <MDXRenderer>{section2.body}</MDXRenderer>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-only-image">
        <div className="row-bg-image-container">
          <StaticImage
            className="row-bg-image"
            layout="fullWidth"
            alt="Trabzon"
            src={
              "https://images.unsplash.com/photo-1489421382202-f7ec0cfd96f7?w=2000"
            }
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            aspectRatio={16 / 9}
            objectPosition="50% 60%"
          />
        </div>
      </div>
      <div className="row row-only-text">
        <div className="row-container">
          <div className="columns">
            <div className="column">
              <MDXRenderer>{section3.body}</MDXRenderer>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-only-image">
        <div className="row-bg-image-container">
          <StaticImage
            className="row-bg-image"
            layout="fullWidth"
            alt="Trabzon"
            src={
              "https://images.unsplash.com/photo-1623064696935-0898cae58292?w=2000"
            }
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
      <Footer section4={section4} formHeading={formHeading} />
    </main>
  );
};

export const query = graphql`
  query ($locale: String!) {
    section1: mdx(
      frontmatter: { key: { eq: "section1" } }
      fields: { locale: { eq: $locale } }
    ) {
      body
    }
    section2: mdx(
      frontmatter: { key: { eq: "section2" } }
      fields: { locale: { eq: $locale } }
    ) {
      body
    }
    section3: mdx(
      frontmatter: { key: { eq: "section3" } }
      fields: { locale: { eq: $locale } }
    ) {
      body
    }
    section4: mdx(
      frontmatter: { key: { eq: "section4" } }
      fields: { locale: { eq: $locale } }
    ) {
      body
    }
    formHeading: mdx(
      frontmatter: { key: { eq: "formHeading" } }
      fields: { locale: { eq: $locale } }
    ) {
      body
    }
  }
`;
export default IndexPage;

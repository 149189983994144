import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as heroStyles from "./hero.module.css";
import { Trans } from "@lingui/macro";

const Hero = () => (
  <div className={`row ${heroStyles.heroRow}`}>
    <div className="row-bg-image-container">
      <div
        className={`row-bg-image-overlay ${heroStyles.heroBgImageOverlay}`}
      ></div>
      <StaticImage
        className="row-bg-image"
        layout="fullWidth"
        alt="Trabzon"
        src={
          "https://images.unsplash.com/photo-1596404839495-0fcee3885f82?w=2000"
        }
        placeholder="blurred"
        formats={["auto", "webp", "avif"]}
      />
    </div>
    <div className={`row-container ${heroStyles.heroRowContainer}`}>
      <h1>
        <Trans id="hero.headline">The Good News of Jesus Christ</Trans>
      </h1>
    </div>
  </div>
);

export default Hero;

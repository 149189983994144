import React from "react";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";
import * as headerStyles from "./header.module.css";

const Header = () => {
  const { config } = useLocalization();
  return (
    <header className={headerStyles.mainHeader}>
      <nav dir="ltr">
        <ul>
          {config.map((item) => (
            <li key={item.code} className={headerStyles.linkContainer}>
              <Link to="/" language={item.code}>
                {item.localName}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
